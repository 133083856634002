*{
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif !important;
}

body{
  background-color: #f1f4f6 !important;
}

a{
  color:inherit;
  text-decoration: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.muiltr-1l8j5k8  {
  left: 0 !important;
}

/* react code input  */

.react-tel-input .form-control {
  width: 100% !important;
}

.react-code-input{
  display: flex !important;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.react-code-input input{
  width:80px !important;
  font-size: 20px !important;
  text-align: center;
  padding: 10px 0px;
}

.CircularProgressbar-text{
  font-size: 28px !important;
  font-weight: bold;
}

/* select currency */
.currency .MuiSelect-select.MuiSelect-outlined{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  outline: 0px !important;
}

.currency select{
  border: 0px;
}

/* home banner overlay */
.overlay{
  position: relative;
}
.overlay::before{
  content: "";
  width: 100%;
  height: 100% ;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
}

/* autocomplete*/
.muirtl-1x5vv0-MuiGrid-root>.MuiGrid-item{
  padding-top: 0px !important;
}
.owl_direction{
  direction: ltr !important;
}
.item_comment{
  width: 60%;
  margin: 20px auto;
}
.rating-start svg{
  width: 20px !important;
  height: 20px !important;
}
.item_comment img{
  width: 100px !important;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 40%;
  margin-top: 22px;
  border: 4px solid #ffdada;
}
/* bullet */
.swiper-wrapper{
  margin-bottom: 50px;
}
  
.swiper-pagination-bullet{
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #d4d4d4 !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
}
  
.swiper-pagination-bullet:hover{
  background-color: #5BC0F8 !important;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #5BC0F8 !important;
}

/* country select */
.country-select{
  width: 100% !important;
  height: 50px !important;
}

/*This style sheet build by eng.reem.shwky@gmail.com*/
.p_1{
  width:90% !important; 
  font-size:14px !important; 
  color:#888 !important;
  text-align:center !important;
  margin-bottom: 10px;
}

.p_2{
  width:90% !important; 
  font-size:14px !important; 
  color:#888 !important;
  text-align:right !important;
  margin-bottom: 10px;
  padding:10px !important;
}
.p_comments{
  width:90% !important; 
  font-size:17px !important; 
  color:#363636 !important;
  text-align:center !important;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 10px;
}
.small_date{
  width:90% !important; 
  font-size:12px !important; 
  color:#888 !important;
  text-align:right !important;
  margin: auto;

  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;
}
.small_date .date{
  float: left;
  padding-top: 10px;
}

.small_date .user{
  float: right;
}
.h2_title{
  width : 90%;
  display:block;
  font-weight:700;
  margin-top: 10px;
  font-size: 16px;
  text-align: center !important;
  padding:10px !important;
  color:#323232;
}
.h3_title{
  width : 90%;
  display:block;
  font-weight:700;
  margin-top: 10px;
  font-size: 16px;
  text-align: right !important;
  padding:10px !important;
  color:#323232;
}
.btndetails{
  border:1px solid #CCC;
  background-color: #FFF;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.btndetails_1{
  border:1px solid #CCC;
  background-color: #FFF;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  float: left;
}

.h4_price{
  color:#800000;
  margin-bottom: 10px;
}
.btndetails:hover{
  color:#FFF;
  background-color: #800000;
}
.btn-details-2{
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  background-color: #ffeaea;
  padding: 10px 20px;
  color:#800000;
  margin: 0% 5px 10px;
}

.breadcrumb{
  margin: auto;
  margin-top: 140px;
  width: 92%;
  direction: rtl;
  text-align: left;
}
.breadcrumb ul li{
  display: inline-block;
  color:#323232;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
}

.breadcrumb ul li:after {
  display: inline-block;
  content: "​/";
  width: 16px;
  padding-left: 10px;
}

.breadcrumb ul li:last-child:after{
  display: none;
  width: 0px;
  color:transparent;
}

.breadcrumb ul li:last-child{
  color:#800000 !important;
}

.p_details{
  width: auto; font-size: 12px; font-weight: 500; color: rgb(136, 136, 136); padding: 5px 10px; background-color: rgb(248, 248, 248); border-radius: 15px; display: inline-block; text-align: center; margin: 5px;
}

.div_block{
  border-radius: 25px;
  padding:8px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: #FFF;
  border-bottom: 1px solid #ccc;
}
.p_email{
  color:#888;
  font-size: 14px;
}
.btn-test{
  background-color: #800000 !important;
  color: #FFF !important;
  font-weight: 500 !important;
  text-align: center !important;
  font-size: 8px !important;
  margin-top: 10px;
  height: 34px;
  width: 30px !important;
  border-radius: 15px !important;
  padding: 0px !important;
  min-width: 35px !important;
  display: block !important;
  margin: 5px auto !important;
  line-height: 51px !important;
}

.btn-test-2{
  background-color: #800000 !important;
  color: #FFF !important;
  font-weight: 500 !important;
  text-align: center !important;
  font-size: 12px !important;
  margin-top: 10px;
  padding: 5px 15px !important;
  border-radius: 15px !important;
  display: block !important;
  margin: 5px auto !important;
  line-height: 30px !important;
}
.table_test{
  width: 90%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
  padding: 10px;
  font-size: 10px;
  text-align: center !important;
}
.table_test tr:first-child{
  background-color: #f1f1f1;
  text-align: center;
  font-weight: 600;
  padding: 10px;
}
.table_test tr:first-child td{
  font-weight: 600;
  padding: 10px;
}
.table_test tr td{
  padding: 5px;
}
/*Table*/
.table_student{
width: 100%;
}
.table_student td .img-profile{
  float: left;
  width: 85px;
  height: 85px;
  object-fit: cover;
  border: 1px solid #CCC;
  border-radius: 35%;
}
.table_student td:nth-child(1){
  background-color: #F8f8f8;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
}
.table_student td:nth-child(2){
  text-align: left;
  background-color: #F8f8f8;
  padding: 10px 20px;
  color:#888;
  font-size: 14px;
}

.hide{
  display: none !important;
}
.textarea{
  width: 100%;
  height: 150px;
}
.notfound{
  color:#888;
  text-align: center;
  width: 90%;
  margin: auto;
  background: #F9f9f9;
    margin: 10px auto;
    font-size: 13px;
    padding: 20px;
}

.tableAdvertiser{
  width: 100%;
  font-size: 14px;
}
.tableAdvertiser td{
  padding: 10px 0px 20px;
  border-bottom: 1px solid #e7e7e7;
}
.tableAdvertiser tr:last-child td{
  border-bottom: none;
}

.offers{
  background-color: #800000;
  color:#FFF;
  font-size: 12px;
  padding: 10px;
  border-radius: 30%;
  position: absolute;
  width: 24px;
  height: 22px;
  text-align: center;
  left: 15px;
  top: 15px;
}
.price {
  width: 80%;
  margin: auto;
}

.price .oldPrice{
  text-decoration: line-through;
  font-size: 12px;
  color:#323232;
  float: right;line-height: 30px;
  width: 100px;
  text-align: center;
}

.price .newPrice{
  font-size: 20px;
  color:#800000;
  float: left;
  width: 100px;
  text-align: center;
}

.txtPrice {
  color:#888;
  font-size: 10px;
  text-align: center;
  display: block;
  text-decoration:none !important;
}

.b-pass{
  text-align: right !important;
  display: block;
  width: 100%;
}
.slider-container{
  width: 90%;
  margin: auto;
}

.slick-prev:before, .slick-next:before{
  color:#800000 !important;
}
.slick-slide{
  direction: rtl;
}

.slick-slide p{
  text-align: center !important;
}

.slick-slide ul{
  text-align: right !important;
}

.slick-slide .p_comments{
  text-align: center !important;
}

.img-discount{
  margin:  20px auto !important;
}

.slider-box{
  padding: 0px;
  width: 100%;
  margin: auto;
}
.box_slider{
  width: 100%;
  display: block;
  position: absolute;
  top:25%;
  text-align: center;
}
.box_slider p{
  text-align: center !important;
  width: 80% !important;
  display: block !important;
  margin: 10px auto !important;
}
.slider-box .slick-slider{
  overflow: hidden !important;
}
.slider-box .slick-prev , .slider-box .slick-next{
  width: 15% !important;
  z-index: 9999999 !important;
}

.contain_title{
text-align: center;
}

.contain_textbox_search{
  display: block !important;
  width: 80% !important;
  max-width: 400px;
  margin: auto !important;
}


.hr {
  border:none;
  height: 2px;
  width: 200px;
  background-color: #800000;
  display: block;
  margin: auto;
}

.slick-slide img.item_comment_user_img{
  width:  30px !important;
  height: 30px !important;
  border-radius: 10px !important;
  display: inline-block !important;
  margin-bottom: 0px !important;
  border: 2px solid #e2e2e2 !important;
  margin-bottom: -10px !important;
  margin-top: 0px !important;
}

.ul_details{
  width:75% !important; 
  margin:10px auto !important; 
  display :block !important; 
  text-align:right !important;
}
.ul_details li{
  display: table;
  width: 100%;
  margin: auto;
}
.ul_details b{
  display: table-cell;
  text-align: right !important;
  width: 50%;
}
.ul_details p{
  display: table-cell;
  width: 50%;
  text-align: left !important;
}